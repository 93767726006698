<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up"
      v-model="drawer"
      absolute
      temporary
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img
            src="../../assets/images/logo/Logo-Inflow-Hor-WB.png"
            alt="Logo"
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>

      <v-list>
        <v-list-group
          v-for="item in homeVersionSidebarDropDownItems"
          :key="item.title"
          v-model="item.active"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            :ripple="false"
            v-for="child in item.items"
            @click="reload()"
            :to="child.to"
            :key="child.title"
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- End sidebar home dropdown item list -->

        <!-- End sidebar services dropdown item list -->

        <!-- End sidebar pages dropdown item list -->

        <!-- End sidebar block dropdown item list -->

        <v-list-item
          :ripple="false"
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- End mobile menu sidebar item list -->
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      elevation="0"
      color="transparent"
      absolute
      class="default-header"
    >
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-0 pa-0 hidden-md-and-up"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->
      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
        <v-menu
          open-on-hover
          bottom
          min-width="240"
          offset-y
          transition="scroll-y-reverse-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn :ripple="false" text v-bind="attrs" v-on="on">
              Servicios
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              @click="reload()"
              v-for="(item, index) in PagesdropDownItems"
              :key="index"
              :to="{ path: item.to }"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- End Home variants dropdown -->

        <!-- End Services dropdown -->
        <v-btn :ripple="false" text to="/blog">Blog</v-btn>
        <!-- End pages dropdown -->

        <!-- End blocks dropdown -->
        <v-btn :ripple="false" text to="/contacto">Contacto</v-btn>
      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import axios from "axios";
import feather from "feather-icons";
export default {
  data: () => ({
    drawer: false,
    items: [
      { title: "Artículos", to: "/blog" },
      { title: "Contacto", to: "/contacto" },
    ],
    PagesdropDownItems: [],

    // Bellow mobile menu items
    homeVersionSidebarDropDownItems: [],
    icon: "menu",
    closeIcon: "x",
  }),
  mounted() {
    this.getServicios();
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    reload() {
      if (this.$route.params.slug) location.reload();
    },
    async getServicios() {
      try {
        const r = await axios(`${this.$apiUrl}/api/product-lines`);
        this.PagesdropDownItems = [];
        r.data.data.forEach((data) => {
          this.PagesdropDownItems.push({
            title: data.attributes.name,
            to: "/servicio/" + data.attributes.slug,
          });
        });
        this.homeVersionSidebarDropDownItems.push({
          items: this.PagesdropDownItems,
          title: "Servicios",
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.feather-menu {
  color: #c6c9d8;
}
</style>
