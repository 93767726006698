import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";
//import Demo from "../views/Demo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Inflow - Crecimiento Basado En Evidencia",
    },
    component: () => import("../views/all-home-version/Business.vue"),
  },
  {
    path: "/servicio/:slug",
    name: "Servicio",
    meta: {
      title: "Servicios | Inflow",
    },
    component: () => import("../views/service/ServiceDetails.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    meta: {
      title: "Blog inflow",
    },
    component: () => import("../views/blog/Blog.vue"),
  },
  {
    path: "/blog/articulo/:slug",
    name: "artículo",
    meta: {
      title: "Blog inflow",
    },
    component: () => import("../views/blog/BlogDetails.vue"),
  },
  {
    path: "/contacto",
    name: "Contacto",
    meta: {
      title: "Contacto | inflow",
    },
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/productos/:slug",
    name: "Productos",
    meta: {
      title: "Producto | Inflow",
    },
    component: () => import("../views/productos/ProductDetails.vue"),
  },
  {
    path: "*",
    component: Missing,
    meta: {
      title: "Error 404 - Página no encontrada",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
